import React from "react";
import {
  Input as AntInput,
  AutoComplete as AntAutoComplete,
  Form as AntForm,
  DatePicker as AntDatePicker,
  Select as AntSelect,
} from "antd";
import {
  FilePdfOutlined,
  FileImageOutlined,
  FileJpgOutlined,
  FileOutlined,
  FileWordOutlined,
  FileExcelOutlined,
  CheckSquareOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { NMCKEditor } from "../CKEditor";
import { Button } from "../Button";
import dayjs from "dayjs";
import "./style.scss";

const getMaxLengthRule = (maxLength) => {
  let max = null;
  if (maxLength !== undefined) {
    max = {
      show: true,
      max: maxLength,
      strategy: (txt) => String(txt).split("").length,
      exceedFormatter: (txt, { max }) =>
        String(txt).split("").slice(0, max).join(""),
    };
  }
  return max;
};

const FileIcon = (props) => {
  const { type, showColon, ...rest } = props;
  let IconComponent = FileOutlined;
  switch (type) {
    case "image/png":
      IconComponent = FileImageOutlined;
      break;
    case "image/jpg":
    case "image/jpeg":
      IconComponent = FileJpgOutlined;
      break;
    case "application/pdf":
      IconComponent = FilePdfOutlined;
      break;
    case "text/csv":
      IconComponent = ({ className, showColon, ...rest }) => {
        return (
          <span className={`icon-file-csv ${className || ""}`} {...rest}></span>
        );
      };
      break;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/msword":
      IconComponent = FileWordOutlined;
      break;
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.ms-excel":
      IconComponent = FileExcelOutlined;
      break;
  }
  return <IconComponent {...rest} />;
};

const HelpText = (props) => {
  const { helpText, error, type } = props;
  return Boolean(helpText) || Boolean(error) ? (
    <div className="col">
      {String(error || "").trim().length > 0 && (
        <React.Fragment>
          <span className="f10 cFE3333">{error}</span>
          <br />
        </React.Fragment>
      )}
      {String(helpText || "").trim().length > 0 && (
        <React.Fragment>
          <span
            dangerouslySetInnerHTML={{ __html: helpText }}
            className="f10 c989898"
          ></span>
          {type === "suffix-pass" && (
            <span className="f10 c989898">
              Click{" "}
              <CheckSquareOutlined
                style={{ fontSize: "10px", color: "#989898" }}
              />{" "}
              to copy the password to clipboard
            </span>
          )}
        </React.Fragment>
      )}
    </div>
  ) : null;
};

const TextInput = React.forwardRef((props, ref) => {
  const {
    type,
    id,
    label,
    placeholder,
    name,
    value,
    onChange,
    readOnly,
    maxLength,
    className,
    rootClassName,
    showColon,
    ...rest
  } = props;
  const count = getMaxLengthRule(maxLength);
  const overflow = rest.overflow !== undefined ? rest.overflow : true;
  const _className = `${rootClassName || "text-input"} border ${
    Boolean(count) ? "no-pad" : ""
  } ${props.font || "f14"} ${readOnly ? "read-only" : ""} ${
    overflow ? "" : "txt-no-wrap"
  } ${className || ""}`;
  return Boolean(readOnly) ? (
    <div className={_className} title={!overflow ? value : ""}>
      {value}
    </div>
  ) : (
    <AntInput
      ref={ref}
      type={type}
      name={name}
      className={_className}
      classNames={{ input: "input-ele", suffix: "input-suffix" }}
      id={`for-${id || name}`}
      value={value}
      onChange={onChange}
      count={count}
      placeholder={placeholder || label}
      {...rest}
    />
  );
});
const TextArea = (props) => {
  const {
    id,
    label,
    placeholder,
    name,
    value,
    onChange,
    readOnly,
    maxLength,
    className,
    showColon,
    ...rest
  } = props;
  const _className = `textarea-input border ${props.font || "f4"} ${
    readOnly ? "read-only" : ""
  } ${className || ""}`;
  return Boolean(readOnly) ? (
    <div className={_className}>{value}</div>
  ) : (
    <AntInput.TextArea
      type="text"
      name={name}
      value={value}
      showCount={maxLength > 0}
      maxLength={maxLength}
      id={`for-${id || name}`}
      className={_className}
      onChange={onChange}
      placeholder={placeholder || label}
      {...rest}
    />
  );
};
const Select = (props) => {
  const {
    optionTemplate,
    label,
    options,
    value,
    onChange,
    name,
    placeholder,
    allowSearch,
    caretColor,
    disabled,
  } = props;
  const handleChange = (newVal) => {
    let option = options.find(({ id }) => id === newVal);
    if (option) {
      onChange && onChange({ target: { name, value: option } });
    }
  };
  const UserTemplate = ({ user, className }) => {
    return (
      <div className="">
        <span className={`f14 c0008 5${className || ""}`}>{user.label}</span>{" "}
        <br />
        <span className={`f12 c00045`}>
          {user.designation}, {user.department}
        </span>
      </div>
    );
  };
  return (
    <AntSelect
      disabled={disabled}
      onChange={handleChange}
      showSearch={allowSearch}
      popupClassName="select-popup"
      placeholder={placeholder}
      value={value && value.id ? value.label : null}
      rootClassName={`select-input ${value ? value.className : ""}`}
      suffixIcon={
        <span
          className={`${caretColor || "cD9D9D9"} ${
            allowSearch ? "f12 icon-search" : "f8 icon-caret-down"
          }`}
        ></span>
      }
      options={(options || []).map((_) => ({ ..._, value: _.id }))}
      optionRender={(option) => {
        return optionTemplate === "USER" ? (
          <UserTemplate
            user={option.data}
            className={option.className}
            key={option.key}
          />
        ) : (
          <span className={`f14 ${option.className || ""}`} key={option.key}>
            {option.label}
          </span>
        );
      }}
      filterOption={(inputValue, option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
      }
      notFoundContent={
        <div className="select-no-found col v-ctr h-ctr">
          <span className="h12 text-center c777">
            No Matching {label || "Result"}
          </span>
        </div>
      }
    />
  );
};
const AutoComplete = (props) => {
  const [inputValue, setInputValue] = React.useState("");
  const { label, options, value, onChange, name, placeholder, search } = props;
  React.useEffect(() => {
    if (value) {
      setInputValue(value.label);
    }
  }, [value]);
  const handleOnSelect = (e, option) => {
    onChange && onChange({ target: { name, value: option } });
  };
  return (
    <AntAutoComplete
      value={inputValue}
      onSelect={handleOnSelect}
      onChange={(value) => {
        setInputValue(value);
      }}
      optionRender={(option) => {
        return (
          <span className={`f14 ${option.className || ""}`} key={option.id}>
            {option.label}
          </span>
        );
      }}
      filterOption={(inputValue, option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
      }
      className="f-rest"
      popupClassName="select-popup"
      options={(options || []).map((_) => ({
        ..._,
        key: _.id,
        value: _.label,
      }))}
    >
      <TextInput
        suffix={
          <span
            className={`cD9D9D9 ${
              search ? "f14 icon-search" : "f10 icon-caret-down"
            }`}
          ></span>
        }
        placeholder={placeholder || label}
        rootClassName="select-input"
        className={`${value ? value.className : ""}`}
      />
    </AntAutoComplete>
  );
};

const FileInput = (props) => {
  const _fileInputRef = React.useRef(null);
  const {
    value,
    className,
    name,
    multiple,
    placeholder,
    label,
    accept,
    allowDelete,
  } = props;
  const handleOnFileChange = (e) => {
    let _files = Array.from(e.target.files);
    if (multiple) {
      _files = [...(value || []), ..._files];
    }
    props.onChange && props.onChange({ target: { name: name, value: _files } });
  };
  const handleSelectFile = (e) => {
    _fileInputRef.current && _fileInputRef.current.click();
  };
  const handleRemoveFile = (index) => {
    props.onDelete && props.onDelete(name, index);
  };
  return (
    <div className={`col ${className || ""}`}>
      {Boolean(
        multiple && allowDelete && Array.isArray(value) && value.length > 0
      ) && (
        <div className="col file-list w-100 oy-auto">
          {Array.prototype.map.call(value, (file, i) => {
            return (
              <div key={i} className="row file-entry w-100">
                <div className="row f-rest">
                  <FileIcon className="f14 file-icon" type={file.type} />
                  <span className="f14 reg c000 text-nxt">{file.name}</span>
                </div>
                <Button
                  type="button"
                  icon="icon-delete c00045 f12"
                  className="btn-delete"
                  variant="lite"
                  onClick={handleRemoveFile.bind(null, i)}
                />
              </div>
            );
          })}
        </div>
      )}
      <div className={`row w-100`}>
        <div className="col text-input border h-ctr c00025">
          {Boolean(allowDelete && multiple) ||
          !Boolean(value) ||
          (Array.isArray(value) && value.length === 0) ? (
            <span className="input-ele f14 reg">
              <span className="cC3C3C3">{placeholder || label}</span>
            </span>
          ) : Array.isArray(value) && value.length === 1 ? (
            <span className="input-ele f14 reg">{value[0].name}</span>
          ) : null}
        </div>
        <Button
          label={props.btnLabel || "Select File"}
          Icon={LinkOutlined}
          iconColor="cFFF"
          className="min select-btn"
          type="button"
          onClick={handleSelectFile}
        />
        <input
          name={name}
          style={{ display: "none" }}
          ref={_fileInputRef}
          type="file"
          multiple={multiple}
          accept={accept}
          onChange={handleOnFileChange}
          hidden={true}
        />
      </div>
    </div>
  );
};
const HTMLInput = (props) => {
  const _ref = React.useRef(null);
  const {
    readOnly,
    disabled,
    value,
    name,
    onChange,
    toolbarType,
    config,
    onInstanceReady,
  } = props;
  const handleInstanceReady = (e) => {
    if (e.editor) {
      e.editor.setData(value);
    }
    onInstanceReady && onInstanceReady(e);
  };
  return (
    <React.Fragment>
      {readOnly || disabled ? (
        <p
          className="ck-container max oy-auto"
          dangerouslySetInnerHTML={{ __html: value }}
        ></p>
      ) : (
        <NMCKEditor
          type={toolbarType}
          config={config}
          onInstanceReady={handleInstanceReady}
          onChange={(e) => {
            if (_ref.current) {
              onChange &&
                onChange({ target: { name, value: _ref.current.getData() } });
            }
          }}
        />
      )}
    </React.Fragment>
  );
};
const DatePicker = (props) => {
  const {
    id,
    label,
    placeholder,
    name,
    value,
    onChange,
    readOnly,
    maxLength,
    className,
    showColon,
    rootClassName,
    minDate,
    ...rest
  } = props;
  const _className = `${rootClassName || "text-input"} border ${
    props.font || "f14"
  } ${readOnly ? "read-only" : ""}`;
  const handleChange = (date) => {
    onChange && onChange({ target: { name, value: date } });
  };
  const handleDisabledDate = (currentDate) => {
    let disabled = false;
    if (minDate) {
      const cDate = currentDate.toDate();
      disabled = dayjs(cDate).isBefore(minDate);
    }
    return disabled;
  };
  return Boolean(readOnly) ? (
    <div className={_className}>{value}</div>
  ) : (
    <AntDatePicker
      name={name}
      placeholder={placeholder || label}
      className={_className}
      onChange={handleChange}
      disabledDate={handleDisabledDate}
      value={value ? dayjs(value) : undefined}
      {...rest}
    />
  );
};
const SuffixPassword = (props) => {
  const ref = React.useRef();
  const [state, setState] = React.useState({ showCopy: false, copied: false });
  const {
    id,
    name,
    value,
    onChange,
    className,
    rootClassName,
    allowPasswordCopy,
    onCopyToClipboard,
    ...rest
  } = props;
  const handleCopyToClipboard = () => {
    if (allowPasswordCopy && state.showCopy) {
      navigator.clipboard.writeText(value.suffix + value.pass);
      setState((_) => ({ ..._, copied: true }));
      onCopyToClipboard && onCopyToClipboard(true);
    }
  };
  React.useEffect(() => {
    setState((_) => ({ ..._, showCopy: value.pass.length > 0, copied: false }));
  }, [value]);
  return (
    <div className="row suffix-pass-input">
      <div className="row pass-suffix v-ctr h-ctr">
        {value ? value.suffix : ""}
      </div>
      <TextInput
        ref={ref}
        name={name}
        value={value ? value.pass : ""}
        onChange={onChange}
        {...rest}
        suffix={
          <div style={{ opacity: allowPasswordCopy && state.showCopy ? 1 : 0 }}>
            {state.copied ? (
              <span className="f10 c44BB44">Copied</span>
            ) : (
              <CheckSquareOutlined
                style={{ fontSize: "14px" }}
                onClick={handleCopyToClipboard}
              />
            )}
          </div>
        }
      />
    </div>
  );
};
export const Input = (props) => {
  const { className, id, label, required, name } = props;
  const type = props.type || "text";
  const showColon = props.showColon !== undefined ? props.showColon : false;
  return (
    <AntForm.Item
      help={<HelpText {...props} />}
      htmlFor={`for-${id || name}`}
      colon={showColon && !required}
      required={required}
      className={`form-input type-${type} ${className || ""} ${name} ${
        required && showColon ? "req-colon" : ""
      }`}
      label={
        label && label.length > 0 ? (
          <span className="f14 c00085 reg label">{label}</span>
        ) : null
      }
    >
      {type === "text" && <TextInput {...props} className="" />}
      {type === "select" && <Select {...props} />}
      {type === "autocomplete" && <AutoComplete {...props} />}
      {type === "textarea" && <TextArea {...props} />}
      {type === "file" && <FileInput {...props} />}
      {type === "html" && <HTMLInput {...props} />}
      {type === "date" && <DatePicker {...props} />}
      {type === "suffix-pass" && <SuffixPassword {...props} />}
    </AntForm.Item>
  );
};

/*
const InputContainer = (props) => {
  return (
    <div className={`container ${props.className || ''} ${props.readOnly ? 'readOnly' : ''}  ${props.disabled ? 'disabled' : ''}`} ref={props.InputPropsRef}>
      {props.children}
    </div>
  )
}

export const AutoComplete = (props) => {
  const [inputValue, setInputValue] = React.useState('')
  const { label, disablePortal, options, placeholder, id, name, value, disabled, readOnly, caretIcon, className, ...rest } = props;
  const direction = rest.direction || 'row'
  const _ref = useRef(null)
  const removeFocus = () => {
    if (_ref && _ref.current) {
      const input = _ref.current.querySelector('input')
      if (input) input.blur();
    }
  }
  const getOptions = () => {
    return options.map((_) => {
      let option = (typeof _ === "string") ? { id: _, label: _ } : _;
      return {
        value: option.id,
        label: (<li className={`f14 ${option.className || ''}`} key={option.id}>{option.label}</li>)
      }
    })
  }
  return (
    <div className={`${direction} form-input ${rest.required ? 'req' : ''} ${readOnly ? 'readOnly' : ''} ${readOnly ? 'readOnly' : ''} ${className || ''}`} >
      {
        Boolean(label && label.length > 0) &&
        <div className='row label-cont'>
          <label className='label f9 c00085 reg' htmlFor={id || name}>{label}</label>
          {rest.showColon && <span className='f9 c00085 colon' >:</span>}
        </div>
      }
      <AntAutoComplete
        options={options}
        value={inputValue}
        onSelect={(e) => {
          console.log('select', e);
        }}
        onChange={(e) => {
          console.log('change', e);
        }}
        optionRender={(option) => {
          return (
            <li className={`f14 ${option.className || ''}`} key={option.id}>{option.label}</li>
          )
        }}
        filterOption={(inputValue, option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
        }
        className='f-rest'>
      </AntAutoComplete>
      <MUIAutocomplete
        openOnFocus
        ref={_ref}
        disablePortal={disablePortal !== undefined ? disablePortal : undefined}
        id={id || name}
        value={value}
        options={options || []}
        onChange={(e, value) => {
          e.preventDefault();
          rest.onChange && rest.onChange({ target: { name: name, value: value } })
          removeFocus()
        }}
        classes={{ root: 'f-rest', listbox: 'dropdown-list', option: "f14 option caps", groupLabel: 'f14 c00085 caps', noOptions: 'f14' }}
        disabled={disabled || readOnly}
        noOptionsText='No Matching Result'
        renderOption={(props, option) => {
          const { className, ...rest } = props
          return (
            <li {...rest} className={`${className || ''} ${option.className || ''}`} key={option.id}>{option.label}</li>
          )
        }}
        renderInput={(params) => {
          const { className, ..._rest } = params.inputProps
          return (
            <InputContainer InputPropsRef={params.InputProps.ref} className='row' readOnly={readOnly} disabled={disabled}>
              <input id={`${id || ''}_input`} type='text' placeholder={placeholder || label} className={`f9 input caps ${className}`} {..._rest} name={rest.name} />
              {!readOnly && <i className={`${caretIcon || 'icon-profile-caret'} c00025 f12`} />}
            </InputContainer>
          )
        }}
      /> 
    </div>
  )
}

export const TextInput = (props) => {
  const { label, type, required, id, name, value, disabled, className, font, showColon, readOnly, radioButtons, radioHints, error, ...rest } = props
  const direction = rest.direction || 'row';
  const isTextArea = type === 'textarea';
  const isRadio = type === 'radio';
  const isHTML = type === 'html';
  const isText = !isTextArea && !isRadio && !isHTML;
  const _ref = useRef(null)
  return (
    <div
      className={`form-input text-input ${direction || 'col'} ${required ? 'req' : ''} ${className || ''} ${isTextArea || isHTML ? 'v-start' : ''} ${isRadio ? 'v-start radio-cont' : ''} ${readOnly ? 'readOnly' : ''}`}>
      {
        Boolean(label) &&
        <div className='row label-cont'>
          <label className='f9 c00085 reg label' htmlFor={`${id || name}_input`}>{label}</label>
        </div>
      }
      <div className='col f-rest'>
        <InputContainer className='col w-100' readOnly={readOnly} disabled={disabled}>
          {
            isTextArea &&
            <React.Fragment>
              {
                readOnly ?
                  <p className={`${font || 'f9'} input`}>{value}</p>
                  :
                  <textarea id={`${id || name}_input`} name={name} className={`${font || 'f9'} input`} {...rest} value={value} disabled={disabled || readOnly}></textarea>
              }
              {Boolean(rest.maxLength) && <h6 className='f9 c00025 reg count line-22'>{(value || '').length}/{rest.maxLength}</h6>}
            </React.Fragment>
          }
          {
            isRadio &&
            <React.Fragment>
              <Radio.Group value={value} onChange={rest.onChange} className={className} name={name} >
                {
                  (radioButtons || []).map((radio) => {
                    return <Radio value={radio.value} key={radio.value}>{radio.label}</Radio>
                  })
                }
              </Radio.Group>
              {
                Boolean(radioHints && radioHints[value]) && <h6 className='hint f9 c777777'>{radioHints[value]}</h6>
              }
            </React.Fragment>
          }
          {
            isHTML &&
            <React.Fragment>
              {
                (readOnly || disabled) ?
                  <p className='ck-container max oy-auto' dangerouslySetInnerHTML={{ __html: value }}></p>
                  :
                  <NMCKEditor
                    initialData={value}
                    onInstanceReady={(e) => _ref.current = e.editor}
                    onChange={(e) => {
                      if (_ref.current) {
                        rest.onChange && rest.onChange({ target: { name, value: _ref.current.getData() } })
                      }
                    }}
                  />
              }
            </React.Fragment>
          }
          {

            isText &&
            <input disabled={readOnly || disabled} id={`${id || name}_input`} type={type || 'text'} name={name} className={`${font || 'f9'} input`} {...rest} value={value} />
          }
        </InputContainer>
        {Boolean(error) && <h6 className='f11 cFE3333 error'>{error}</h6>}
      </div>
    </div>
  )
}
export const Dropdown = (props) => {
  const { label, id, font, name, disabled, className, primary, options, caretClassName, readOnly, ...rest } = props
  return (
    <Select
      label={label} id={id || name} name={name} disabled={disabled} classes={{ select: 'select' }}
      IconComponent={() => <i className={`icon-profile-caret f11 caret ${caretClassName || 'c00085'}`} />}
      className={`dropdown ${className || ''}  ${primary && 'primary'} ${readOnly ? 'readOnly' : ''}`} variant='outlined' {...rest}>
      {
        (options || []).map((option) => {
          return (
            <MenuItem key={option.value} value={option.value} className={`dropdown-item c0085 ${primary && 'primary'}`}>
              <div className='row'>
                {Boolean(option.icon) && <i className={`icon ${font || 'f9'} ${option.icon}`} />}
                <h6 className={`label ${font || 'reg f9'} ${option.className || ''}`}>{option.label}</h6>
              </div>
            </MenuItem>
          )
        })
      }
    </Select>
  )
}
*/
const validationHelper = (fields, values) => {
  let errors = {},
    valid = true;
  for (let i = 0; i < fields.length; i++) {
    const { attribute, required, label } = fields[i];
    if (required && !values[attribute]) {
      errors[attribute] = `Please Enter the ${label || ""}`;
      valid = false;
    }
  }
  return { errors, valid };
};
export const Form = React.forwardRef((props, ref) => {
  const [errors, setErrors] = React.useState({});
  const {
    direction,
    Fields,
    formData,
    onChange,
    getOptions,
    onSubmit,
    className,
    showColon,
    hideErrorMsg,
    labelAlign,
  } = props;
  const formDisabled = props.disabled;

  // Update errors when new errors are received from props
  React.useEffect(() => {
    setErrors(props.errors || {});
  }, [props.errors]);

  const handleSubmit = (e, skipSubmit) => {
    e && e.preventDefault && e.preventDefault();
    const { valid, errors } = validationHelper(Fields, formData);
    if (!valid) {
      if (!Boolean(hideErrorMsg)) {
        setErrors(errors);
      }
      return;
    }
    if (!skipSubmit) {
      onSubmit && onSubmit();
    }
    return valid;
  };
  const handleChange = (e) => {
    const { name } = e.target;
    const _errors = { ...errors };
    _errors[name] = false;
    setErrors(_errors);
    onChange && onChange(e);
  };
  React.useImperativeHandle(ref, () => ({ validate: handleSubmit }));
  return (
    <AntForm
      labelWrap
      labelAlign={labelAlign || "left"}
      layout={direction || "horizontal"}
      rootClassName={`w-100 ${className || ""}`}
      onFinish={handleSubmit}
      noValidate
    >
      {Fields.map(({ attribute, className, disabled, ...rest }, i) => {
        let options =
          typeof getOptions === "function" ? getOptions(attribute) : [];
        const p = {
          value: formData[attribute] || "",
          key: attribute,
          error: errors && errors[attribute],
          className: `${className || "w-100"}`,
          name: attribute,
          onChange: handleChange,
          options: options,
          showColon: showColon,
          disabled: disabled || formDisabled,
          ...rest,
        };
        if (
          p.type === "html" &&
          typeof props.onHtmlEditorReady === "function"
        ) {
          p.onInstanceReady = props.onHtmlEditorReady.bind(null, attribute);
        }
        if (p.type === "file" && props.onDeleteFile) {
          p.onDelete = props.onDeleteFile.bind(null, attribute);
        }
        if (p.type === "suffix-pass" && props.onCopyToClipboard) {
          p.onCopyToClipboard = props.onCopyToClipboard.bind(attribute);
        }
        return <Input {...p} key={i} />;
      })}
    </AntForm>
  );
});
