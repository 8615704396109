import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { DownloadOutlined, MailOutlined } from "@ant-design/icons";
import {
  Form,
  LinkButton,
  OvalLoading,
  Button,
  DataTable,
  getModuleLink,
  Modal,
  Menu,
  useNavigateBack,
  AIMLButtonGIF,
  AIMLPopup,
  AIMLPopupGIF,
} from "../../../components";
import { IncideSideBar } from "./SideBar";
import { IncidentHistory } from "./History";
import { IncidentReport } from "./Report";
import { Collapse } from "antd";
import dayjs from "dayjs";
import FormFields from "./FormFields.json";
import {
  incidentActions,
  getCurrentViewIncident,
  getStatusTypes,
  getSeverityTypes,
  getStatusTypeById,
  getSeverityTypeById,
  getCurrentUser,
  getDepartments,
  getProrityTypes,
  getDateInFormat,
  getDueDateByPriority,
  getReportPassword,
  getIncidentReport,
  getZoomLevels,
  isOrganizer,
  isAdmin,
  isArchitect,
  encyptDataObject,
  isGoverner,
  getAIIncidentDetails,
} from "../../../store";
import { UpdateWithEvalButton } from "./AIMLButton";

const MenuItems = [
  { dialogId: "download", label: "Export (as Encrypted PDF)", className: "" },
  { dialogId: "share", label: "Share (Encrypted PDF by Email)", className: "" },
];
const CKConfig = {
  editorplaceholder: "",
};

const TaskColumns = [
  {
    title: "Task ID",
    dataIndex: "task_id",
    key: "task_id",
    width: "136px",
    className: "exo2 f14",
    render: "link",
    variant: "lite",
    color: "#0033CC",
  },
  {
    title: "Priority",
    dataIndex: "priority",
    key: "priority",
    width: "112px",
    className: "exo2 f14",
    render: "color-code",
  },
  {
    title: "Task Name",
    dataIndex: "name",
    key: "name",
    width: "50%",
    className: "exo2 f14",
    cellClassName: "txt-no-wrap",
  },
  {
    title: "Responsible",
    dataIndex: "responsible",
    key: "responsible",
    width: "190px",
    className: "exo2 f14",
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "department",
    width: "140px",
    className: "exo2 f14",
  },
  {
    title: "Status",
    dataIndex: "task-status",
    key: "task-status",
    width: "100px",
    className: "exo2 f14",
    render: "color-text",
  },
];

const CreateTaskModal = (props) => {
  const dispatch = useDispatch();
  const _formRef = React.useRef(null);
  const { open, onClose, incident } = props;
  const [task, setTask] = React.useState({});
  const departments = useSelector(getDepartments);
  const priorities = useSelector(getProrityTypes);
  React.useEffect(() => {
    let task = {};
    if (open && incident) {
      task.source = `Incidents/${incident.incident_id}: ${incident.title}`;
    }
    setTask(task);
  }, [open]);
  const getFormFields = () => {
    const fields = FormFields.Task.map((_) => ({ ..._ }));
    fields[fields.length - 1].minDate = getDateInFormat();
    return fields;
  };
  const getOptions = (attribute) => {
    let options = [];
    switch (attribute) {
      case "dept_name":
        options = departments;
        break;
      case "priority":
        options = priorities;
        break;
    }
    return options;
  };
  const handleCreateTask = (e) => {
    e.preventDefault && e.preventDefault();
    if (_formRef.current && _formRef.current.validate) {
      let valid = _formRef.current.validate(e, true);
      if (!valid) {
        return;
      }
    }
    const _task = {};
    FormFields.Task.forEach(({ attribute, type }) => {
      let value = task[attribute] || "";
      if (type === "select" && value) {
        value = value.id ? value.id : value;
      }
      _task[attribute] = value;
    });
    let payload = { task: _task, incident_id: incident.id };
    dispatch(incidentActions.createIncidentTask(payload));
    props.onComplete && props.onComplete();
    props.onClose && props.onClose();
  };
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    const _task = { ...task };
    if (name === "priority" && value) {
      let offset = getDueDateByPriority(value.id);
      if (offset) {
        offset = dayjs().add(offset, "day").format();
        _task.due_date = offset;
      }
    }
    _task[name] = value;
    setTask(_task);
  };
  return (
    <Modal
      open={open}
      width={"70vw"}
      actions={[
        {
          label: "Cancel",
          variant: "lite",
          className: "min gap",
          color: "#0133CC",
          onClick: onClose,
        },
        { label: "Create", onClick: handleCreateTask },
      ]}
      title={<span className="exo2 f24 med 00085">Create New Task</span>}
    >
      {open && (
        <Form
          showColon
          ref={_formRef}
          className="row fwarp create-task-form"
          formData={task}
          Fields={getFormFields()}
          getOptions={getOptions}
          onChange={handleFieldChange}
          onSubmit={handleCreateTask}
        />
      )}
    </Modal>
  );
};

const InvalidStatusConfirmModal = (props) => {
  const dispatch = useDispatch();
  const _formRef = React.useRef();
  const { open, onClose, incident } = props;
  const [justification, setJustification] = React.useState("");
  React.useEffect(() => {
    if (!open) {
      setJustification("");
    }
  }, [open]);
  const handleSubmit = (e) => {
    e.preventDefault && e.preventDefault();
    if (_formRef.current && _formRef.current.validate) {
      let valid = _formRef.current.validate(e, true);
      if (!valid) {
        return;
      }
    }
    const payload = new FormData();
    payload.append("incident[status]", "Invalid");
    payload.append("incident[status_justificaiton]", justification);
    payload.append("incident_id", incident.id);
    dispatch(incidentActions.updateIncident({ payload }));
    onClose && onClose();
  };
  return (
    <Modal
      className="mid"
      open={open}
      width={500}
      actions={[
        {
          label: "Cancel",
          variant: "lite",
          className: "min gap",
          color: "#0133CC",
          onClick: onClose,
        },
        {
          label: "Submit",
          onClick: handleSubmit,
          disabled: justification.length === 0,
        },
      ]}
      title={
        <span className="f20 exo2 med cBB2222D9">Incident is Invalid?</span>
      }
    >
      <p className="f14 reg c00085 line-22">
        Status for the Incident will be marked as Invalid.
        <br />
        All Responders will be notified by email.
        <br />
        Further changes to this Incident WILL NOT be permitted.
        <br />
        <br />
        Provide a justification for this Status change.
        <br />
        IMPORTANT! This justification will be added to the Incident History.
      </p>
      <Form
        className="col"
        formData={{ justification }}
        Fields={FormFields.InvalidModal}
        onChange={(e) => setJustification(e.target.value)}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

const SeverityConfirmModal = (props) => {
  const dispatch = useDispatch();
  const { open, onClose, incident, updated, onComplete } = props;
  const handleSubmit = (e) => {
    e.preventDefault && e.preventDefault();
    const payload = new FormData();
    payload.append("incident[severity]", updated.id);
    payload.append("incident_id", incident.id);
    dispatch(incidentActions.updateIncident({ payload }));
    onComplete && onComplete();
    onClose && onClose();
  };
  return (
    <Modal
      open={open}
      width={517}
      actions={[
        {
          label: "Cancel",
          variant: "lite",
          className: "min gap",
          color: "#0133CC",
          onClick: onClose,
        },
        { label: "Change", onClick: handleSubmit },
      ]}
      title={<span className="exo2 f20 med c238787">Change the Severity</span>}
    >
      <p className="f14 reg 00085">
        Please confirm that you would like to change the Severity of this
        incident from {incident.severity} to {updated ? updated.label : ""}
      </p>
    </Modal>
  );
};

const ShareConfirmModal = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const { open, onClose, incident, shareInfo, onComplete } = props;
  const getEmails = () => {
    const emailIds = shareInfo.emailIds;
    const orgEmailDomains = currentUser.ent_org.email_domain
      .split(",")
      .map((domain) => {
        return domain[0] === "@" ? domain.replace("@", "") : domain;
      });
    return emailIds.split(",").map((email) => {
      const emailArray = email.split("@");
      const external = orgEmailDomains.indexOf(emailArray[1]) === -1;
      return { emailArray, external };
    });
  };
  const handleSend = () => {
    dispatch(incidentActions.shareIncidentReport(shareInfo));
    onComplete && onComplete();
  };
  return (
    <Modal
      open={open}
      width={517}
      actions={[
        {
          label: "Send",
          variant: "lite",
          color: "#0133CC",
          onClick: handleSend,
        },
        {
          label: "Cancel",
          className: "min gap",
          color: "#0133CC",
          onClick: onClose,
        },
      ]}
      className="share-confirm"
      title={
        <span className="exo2 f20 bold c238787">Confirm Sharing By Email!</span>
      }
    >
      {open && (
        <p className="f14 reg 00085">
          Are you sure that you want to send the following Incident Report with
          email <br /> addresses provided by you?
          <br />
          <span>
            Incident ID : <span className="bold">{incident.incident_id}</span>
          </span>
          <br />
          <span>Incident Title : {incident.title}</span> <br />
          <br />
          <span>Email Address(s)</span>
          <ol className="emails">
            {getEmails().map((_) => {
              return (
                <li key={_.email}>
                  <span className={`${_.external ? "cc0000" : ""}`}>
                    {_.emailArray[0]}@
                    <span className={`${_.external ? "bold" : ""}`}>
                      {_.emailArray[1]}
                    </span>
                  </span>
                </li>
              );
            })}
          </ol>
        </p>
      )}
    </Modal>
  );
};

const ExportIncidentModal = (props) => {
  const dispatch = useDispatch();
  const _formRef = React.useRef(null);
  const currentUser = useSelector(getCurrentUser);
  const { open, onClose, incident, type } = props;
  const [state, setState] = useState({ shareInfo: null });
  const initialform = {
    pwd: getReportPassword(currentUser.ent_org),
    email: "",
    passCopied: false,
  };
  const [form, setForm] = React.useState(
    JSON.parse(JSON.stringify(initialform))
  );
  const isDownload = type === MenuItems[0].dialogId;
  useEffect(() => {
    if (!open) {
      setForm(JSON.parse(JSON.stringify(initialform)));
    }
  }, [open]);
  const handleSubmit = (e) => {
    e.preventDefault && e.preventDefault();
    const body = {
      password: `${form.pwd.suffix}${form.pwd.pass}`,
      incidentId: incident.id,
      filename: `${incident.incident_id} - ${incident.title}.pdf`,
    };
    if (isDownload) {
      dispatch(incidentActions.downloadIncidentReport(body));
      onClose && onClose();
    } else {
      body.emailIds = form.email;
      handleShareConfirm(body);
    }
  };
  const handleShareConfirm = (body, close) => {
    setState((_) => ({ ..._, shareInfo: body }));
    if (close) {
      onClose && onClose();
    }
  };
  const handleFieldChange = (e) => {
    let { name, value } = e.target;
    const _form = { ...form };
    if (name === "pwd") {
      value = { ..._form[name], pass: value };
      _form.passCopied = false;
    }
    _form[name] = value;
    setForm(_form);
  };
  const isdisabled = () => {
    let disabled = Boolean(
      form.pwd.pass.length === 0 || (isDownload && !form.passCopied)
    );
    if (!disabled && !isDownload) {
      disabled = form.email.length === 0;
    }
    return disabled;
  };
  const handleCopyToClipboard = (isCopied) => {
    const _form = { ...form };
    _form.passCopied = isCopied;
    setForm(_form);
  };
  return (
    <React.Fragment>
      <Modal
        open={open}
        width={517}
        actions={[
          {
            label: "Cancel",
            variant: "lite",
            className: "min gap",
            color: "#0133CC",
            onClick: onClose,
          },
          {
            label: isDownload ? "Download" : "Share",
            onClick: handleSubmit,
            disabled: isdisabled(),
            iconColor: "cFFF",
            Icon: isDownload ? DownloadOutlined : MailOutlined,
          },
        ]}
        className=""
        title={
          <span className="exo2 f20 bold c238787">
            {isDownload ? "Download" : "Share"} Incident Report{" "}
            {incident.incident_id}
          </span>
        }
      >
        {open && (
          <Form
            showColon
            ref={_formRef}
            className="col export-form"
            formData={{ ...form }}
            Fields={isDownload ? FormFields.Download : FormFields.Share}
            onChange={handleFieldChange}
            onCopyToClipboard={handleCopyToClipboard}
            onSubmit={handleSubmit}
          />
        )}
      </Modal>
      <ShareConfirmModal
        open={Boolean(state.shareInfo)}
        shareInfo={state.shareInfo}
        incident={incident}
        onComplete={handleShareConfirm.bind(null, null, true)}
        onClose={handleShareConfirm.bind(null, null, false)}
      />
    </React.Fragment>
  );
};
export const ViewIncidentScreen = () => {
  const { incidentId } = useParams();
  const _editors = React.useRef({});
  const dispatch = useDispatch();
  const goBack = useNavigateBack();
  const currentUser = useSelector(getCurrentUser);
  const severities = useSelector(getSeverityTypes);
  const statuses = useSelector(getStatusTypes);
  const incident = useSelector(getCurrentViewIncident);
  const aiIncidentDetails = useSelector(getAIIncidentDetails);
  const report = getIncidentReport(incident);
  const isOrganizerUser = isOrganizer(currentUser);
  const isClosed =
    incident && String(incident.status).trim().toLowerCase() === "closed";
  const isInvalid =
    incident && String(incident.status).trim().toLowerCase() === "invalid";
  const [state, setState] = React.useState({
    updatedIncident: {},
    showCreateTask: false,
    menuTarget: null,
    tasks: null,
    allowViewTasks: false,
  });
  const [showPopup, setShowPopup] = useState(false);

  // Clear AI Incident Details on component unmount
  React.useEffect(() => {
    return () => {
      dispatch(incidentActions.setAIIncidentDetails(null));
    };
  }, [dispatch]);
  React.useEffect(() => {
    if (incidentId) {
      dispatch(incidentActions.fetchIncident({ incidentId }));
      dispatch(incidentActions.fetchPriorities());
    }
  }, []);
  React.useEffect(() => {
    if (incident) {
      createTaskLists();
    }
  }, [incident]);
  React.useEffect(() => {
    if (aiIncidentDetails) {
      const impactVal = aiIncidentDetails.Impact
        ? aiIncidentDetails.Impact.join("<br />")
        : "";
      const observationVal = aiIncidentDetails.Observation
        ? aiIncidentDetails.Observation.join("<br />")
        : "";
      const rcaVal = aiIncidentDetails.RootCauseAnalysis
        ? aiIncidentDetails.RootCauseAnalysis.join("<br />")
        : "";
      const learningVal = aiIncidentDetails.Learning
        ? aiIncidentDetails.Learning.join("<br />")
        : "";
      const evidenceVal = aiIncidentDetails.Evidence
        ? aiIncidentDetails.Evidence.join("<br/>")
        : "";

      let temp = { ...state.updatedIncident };
      temp["impact"] = impactVal;
      temp["observation"] = observationVal;
      temp["rca"] = rcaVal;
      temp["evidence_expected"] = evidenceVal;
      temp["learning"] = learningVal;
      setState((_) => ({ ..._, updatedIncident: temp }));
      // Update the CKEditor instances manually if they exist
      if (_editors["impact"]) _editors["impact"].setData(impactVal);
      if (_editors["observation"])
        _editors["observation"].setData(observationVal);
      if (_editors["rca"]) _editors["rca"].setData(rcaVal);
      if (_editors["learning"]) _editors["learning"].setData(learningVal);
      if (_editors["evidence_expected"])
        _editors["evidence_expected"].setData(evidenceVal);
    }
  }, [aiIncidentDetails]);

  const getFormData = () => {
    let formData = { ...state.updatedIncident };
    if (Boolean(incident)) {
      let severity = getSeverityTypeById(severities, incident.severity);
      formData.severity = Boolean(severity)
        ? {
            ...severity,
            className: `severity ${String(severity.id).toLowerCase()}`,
          }
        : "";
      let status = getStatusTypeById(statuses, incident.status);
      formData.status = Boolean(status)
        ? { ...status, className: `status ${String(status.id).toLowerCase()}` }
        : "";
      FormFields.Details.forEach(({ attribute, readOnly }) => {
        if (!formData[attribute]) {
          formData[attribute] = incident[attribute];
        }
      });
      // // Merge AI Incident Details if they exist
      // if (aiIncidentDetails) {
      //   formData.impact = aiIncidentDetails.Impact ? aiIncidentDetails.Impact.join('<br />') : '';
      //   formData.observation = aiIncidentDetails.Observation ? aiIncidentDetails.Observation.join('<br />') : '';
      //   formData.rca = aiIncidentDetails.RootCauseAnalysis ? aiIncidentDetails.RootCauseAnalysis.join('<br />') : '';
      //   formData.evidence_expected = aiIncidentDetails.Evidence ? aiIncidentDetails.Evidence.join('<br />') : '';
      //   formData.learning = aiIncidentDetails.Learning ? aiIncidentDetails.Learning.join('<br />') : '';
      // }
    }
    // console.log({ formData })
    // if (formData?.entries) {
    //   for (let [key, value] of formData.entries()) {
    //     console.log(`Key: ${key}, Value: ${value}`);
    //   }
    // }

    return formData;
  };
  const updateStatusToInprogress = () => {
    if (String(incident.status).toLowerCase() === "open") {
      const payload = new FormData();
      payload.append(`incident[status]`, "Inprogress");
      payload.append("incident_id", incident.id);
      dispatch(incidentActions.updateIncident({ payload, noMessage: true }));
    }
  };
  const getOptions = (attribute) => {
    let options;
    switch (attribute) {
      case "severity":
        options = severities.map((_) => ({
          ..._,
          className: `severity ${String(_.id).toLowerCase()}`,
        }));
        break;
      case "status":
        options = statuses.map((_) => ({
          ..._,
          className: `status ${String(_.id).toLowerCase()}`,
        }));
        break;
      case "zoom":
        options = getZoomLevels();
        break;
      default:
        options = [];
        break;
    }
    return options;
  };
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    const updatedIncident = { ...state.updatedIncident };
    updatedIncident[name] = value;
    setState((_) => ({ ..._, updatedIncident }));
    if (name === "severity") {
      setState((_) => ({ ..._, showSeverityConfirm: true }));
    } else if (name === "status") {
      if (value && String(value.id).toLowerCase() === "invalid") {
        setState((_) => ({ ..._, showInvalidConfirm: true }));
      } else {
        handleSubmitDetails(e, updatedIncident);
      }
    }
  };
  const handleSubmitDetails = (e, updatedIncident) => {
    e.preventDefault && e.preventDefault();
    let payload = new FormData();
    const _updatedIncident = updatedIncident
      ? updatedIncident
      : state.updatedIncident;

    [...FormFields.Header, ...FormFields.Details].forEach(
      ({ type, attribute }) => {
        let value = _updatedIncident[attribute];
        if (type === "select" && value) {
          value = value.id ? value.id : value;
        } else if (type === "html" && _editors[attribute]) {
          value = _editors[attribute].getData();
        }
        if (value) {
          payload.append(`incident[${attribute}]`, value);
        }
      }
    );
    payload.append("incident_id", incident.id);
    // for (let [key, value] of payload.entries()) {
    //   console.log(`Key: ${key}, Value: ${value}`);
    // }
    dispatch(incidentActions.updateIncident({ payload }));
    if (!payload.get("incident[status]")) {
      setTimeout(() => {
        updateStatusToInprogress(); //an async behavior was observed, sometimes the status was showing open sometimes inprogress, so a delay was introduced to ensure this function runs at the end//
      }, 500);
    }
  };
  const checkTaskAccess = (task) => {
    return (
      isOrganizerUser ||
      isAdmin(currentUser) ||
      isArchitect(currentUser) ||
      currentUser.id === task.assignee_id ||
      currentUser.id === task.supervisor_id
    );
  };
  const getTaskLink = (taskGUID) => {
    let arclink = getModuleLink("arc", currentUser);
    // arclink = `http://localhost:3002/login?sessionToken=${currentUser.session_token}`;
    let redirect = { path: "/T" };
    if (taskGUID) {
      redirect.path += "/" + taskGUID;
    } else {
      redirect.search = `?incident=${incident.guid}`;
    }
    redirect = encyptDataObject(redirect);
    return `${arclink}&redirect=${redirect}`;
  };
  const createTaskLists = () => {
    let rows = [],
      allowViewTasks = false;
    if (Array.isArray(incident.tasks)) {
      rows = incident.tasks.map((_) => {
        let task = { ..._ };
        if (checkTaskAccess(task)) {
          task.link = getTaskLink(task.guid);
          task.linkTarget = "_blank";
          allowViewTasks = true;
        } else {
          task.unlink = true;
        }
        return { ...task };
      });
    }
    setState((_) => ({ ..._, tasks: rows, allowViewTasks }));
  };

  const handleTaskModal = (value) => {
    setState((_) => ({ ..._, showCreateTask: value }));
  };
  const handle3DotMenu = (e) => {
    setState((_) => ({ ..._, menuTarget: e.currentTarget }));
  };
  const handleMenuItemClick = (e, menu) => {
    setState((_) => ({
      ..._,
      menuTarget: null,
      exportDialogType: menu ? menu.dialogId : null,
    }));
  };
  const handleHTMLEditor = (name, e) => {
    if (e.editor) {
      _editors[name] = e.editor;
      _editors[name].document.getBody().setStyle("text-align", "justify");
    }
  };
  const getFormFields = (formID) => {
    let fields = [];
    if (formID === "header") {
      fields = isClosed ? FormFields.Zoom : FormFields.Header;
    } else if (formID === "details") {
      fields = FormFields.Details.map((_) => {
        let field = { ..._, config: CKConfig };
        if (
          field.attribute === "summary" &&
          (isOrganizerUser || isGoverner(currentUser))
        ) {
          field.readOnly = false;
        }
        return field;
      });
    }
    if (isInvalid) {
      fields = fields.map((_) => {
        let disabled = _.attribute === "status" ? !isOrganizerUser : true;
        return { ..._, disabled };
      });
    }
    return fields;
  };
  const handleGoBack = (e) => {
    e.preventDefault();
    goBack();
  };
  const handleUpdateWithEvalButton = (e) => {
    e.stopPropagation();
    setShowPopup(true);
    const body = new FormData();
    body.append("incident[title]", incident?.title);
    body.append("incident[department]", incident?.department);
    body.append("incident[severity]", incident?.severity);
    body.append("incident[summary]", incident?.summary);
    const handleResponseComplete = () => {
      setShowPopup(false);
    };
    dispatch(
      incidentActions.fetchIncidentDetailsFromAI({
        body,
        onComplete: handleResponseComplete,
      })
    );
  };

  return (
    <div className="col w-100 h-100 o-hide view-inc">
      {Boolean(incident) ? (
        <React.Fragment>
          <div className="col w-100 header">
            <LinkButton
              variant="lite"
              className="f14 reg btn-back"
              color="#0133CC"
              link="/"
              label="Incident Tracker /"
              onClick={handleGoBack}
            />
            <div className="row w-100 h-btn">
              <span className="f20 exo2 c238787 bold">
                {incident.incident_id}: {incident.title}
              </span>
              <div className="row fields">
                <Form
                  showColon
                  className="row"
                  formData={getFormData()}
                  getOptions={getOptions}
                  onChange={handleFieldChange}
                  Fields={getFormFields("header")}
                />
                {isClosed && (
                  <Button
                    className="col v-ctr h-ctr btn-menu f8"
                    icon="icon-dot c00085"
                    variant="lite"
                    onClick={handle3DotMenu}
                  />
                )}
              </div>
            </div>
          </div>
          {isClosed && (
            <IncidentReport
              zoom={state.updatedIncident.zoom && state.updatedIncident.zoom.id}
              report={report}
            />
          )}
          {!isClosed && (
            <div className="row f-rest o-hide container">
              <div className="row w-100 h-100 o-hide">
                <div className="row f-rest o-hide h-100">
                  <div className="row w-100 h-100 o-hide">
                    <IncidentHistory
                      incident={incident}
                      onComplete={updateStatusToInprogress}
                    />
                    <div className="col f-rest o-hide h-100">
                      <div className="col h-100 toggle-sec oy-auto">
                        <Collapse
                          defaultActiveKey={["task"]}
                          rootClassName="collapse"
                        >
                          <Collapse.Panel
                            key="task"
                            className="panel task-view"
                            header={<span className="f14 reg 00085">Task</span>}
                          >
                            {!isInvalid && (
                              <div className="row h-end">
                                <LinkButton
                                  label="View Tasks"
                                  target="_blank"
                                  disabled={!state.allowViewTasks}
                                  href={
                                    state.allowViewTasks ? getTaskLink() : "#"
                                  }
                                />
                                <Button
                                  label="Create Task"
                                  className="create-task-btn"
                                  icon="icon-plus-lite"
                                  iconColor="cFFF"
                                  onClick={handleTaskModal.bind(null, true)}
                                />
                              </div>
                            )}
                            <div className="col w-100">
                              <DataTable
                                noSelection
                                emptyMessage="There are no tasks right now!"
                                Columns={TaskColumns}
                                rows={state.tasks}
                              />
                            </div>
                          </Collapse.Panel>
                        </Collapse>
                        <Collapse
                          defaultActiveKey={["details"]}
                          rootClassName="collapse"
                        >
                          <Collapse.Panel
                            key="details"
                            className="panel"
                            header={
                              <div className="header-content">
                                <span className="f14 reg 00085">Details</span>
                                <div className="button-wrapper">
                                  {/* <AIMLButtonGIF className="gif-loader" /> */}
                                  <UpdateWithEvalButton
                                    handleUpdateWithEval={
                                      handleUpdateWithEvalButton
                                    }
                                  />
                                </div>
                              </div>
                            }
                          >
                            <div className="col w-100">
                              <Form
                                showColon
                                className="col details-form"
                                formData={getFormData()}
                                onChange={handleFieldChange}
                                onSubmit={handleSubmitDetails}
                                onHtmlEditorReady={handleHTMLEditor}
                                Fields={getFormFields("details")}
                              />
                              {!isInvalid && (
                                <div className="row h-end">
                                  <Button
                                    label="Submit"
                                    onClick={handleSubmitDetails}
                                  />
                                </div>
                              )}
                            </div>
                          </Collapse.Panel>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </div>
                <IncideSideBar
                  incident={incident}
                  onComplete={updateStatusToInprogress}
                />
              </div>
            </div>
          )}
          <CreateTaskModal
            incident={incident}
            open={state.showCreateTask}
            onClose={handleTaskModal.bind(null, false)}
            onComplete={updateStatusToInprogress}
          />
          <InvalidStatusConfirmModal
            incident={incident}
            open={state.showInvalidConfirm}
            onClose={() =>
              setState((_) => ({ ..._, showInvalidConfirm: false }))
            }
          />
          <SeverityConfirmModal
            incident={incident}
            open={state.showSeverityConfirm}
            onComplete={updateStatusToInprogress}
            updated={
              state.updatedIncident.severity
                ? state.updatedIncident.severity
                : null
            }
            onClose={() =>
              setState((_) => ({ ..._, showSeverityConfirm: false }))
            }
          />
          <ExportIncidentModal
            incident={incident}
            type={state.exportDialogType}
            open={Boolean(state.exportDialogType)}
            onClose={handleMenuItemClick}
          />
          <Menu
            anchorEl={state.menuTarget}
            onMenuClick={handleMenuItemClick}
            onClose={() => handle3DotMenu({ currentTarget: null })}
            menuItems={MenuItems.map((_) => ({
              ..._,
              disabled: !Boolean(report),
            }))}
          />
          {showPopup ? (
            <div className="popup-overlay">
              <div className="popup-wrapper">
                <AIMLPopupGIF className="popup-gif-loader" />
                <div className="popup-content">
                  <AIMLPopup className="aiml-popup" />
                </div>
              </div>
            </div>
          ) : null}
        </React.Fragment>
      ) : (
        <OvalLoading />
      )}
    </div>
  );
};
