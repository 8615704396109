import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  SagaRegistry,
  setAlertAndLoading,
  axios,
  requestHandler,
  getDateIn24Format,
  dateSorter,
  getUserDetails,
  fetchS3ObjectAsBlob,
  getDateInFullYearIn24Format,
} from "../app";
import { incidentActions } from "./slice";
import { getDepartments } from "../common";
import { saveAs } from "file-saver";

function* getIncident(_, download = true) {
  const incident = JSON.parse(JSON.stringify(_));
  incident.created_at = getDateIn24Format(incident.created_at);
  getUserDetails(incident, "creator");
  incident.incident_responders = (incident.incident_responders || []).map(
    (_) => {
      getUserDetails(_, "responder");
      return _;
    }
  );
  if (download) {
    for (let i = 0; i < incident.attachments.length; i++) {
      if (
        incident.attachments[i].title === "saved_incident_report" &&
        incident.status === "closed"
      ) {
        // incident.attachments[i].evidence_url_full = axios.getFullPath(`/educators/s3_download_file?url=${incident.attachments[i].evidence_url}`);
        incident.attachments[i].blob = yield fetchS3ObjectAsBlob(
          incident.attachments[i].encrypt_evidence_url
        );
        break;
      }
    }
  }
  if (Array.isArray(incident.comments)) {
    let appendixID = 1;
    incident.comments = incident.comments.map((_, index) => {
      const comment = { ..._ };
      if (index === 0) {
        comment.attachments = incident.attachments;
      }
      comment.attachments = comment.attachments.map((_) => {
        let attachment = { ..._ };
        attachment.appendixID = `${
          appendixID < 10 ? "00" : appendixID < 100 ? "0" : ""
        }${appendixID}`;
        appendixID++;
        return attachment;
      });
      getUserDetails(comment, "creator");
      comment.created_at = getDateInFullYearIn24Format(comment.created_at);
      return comment;
    });
  }
  if (Array.isArray(incident.tasks)) {
    incident.tasks.sort(dateSorter.bind(null, "created_at"));
    incident.tasks = incident.tasks.map((_) => {
      const assignee = _.assignee;
      let responsible = "-";
      if (assignee) {
        getUserDetails(assignee);
        responsible = assignee._name;
      }
      return { ..._, "task-status": _.status, responsible: responsible };
    });
  }
  return incident;
}
function* fetchSeverityTypes() {
  yield requestHandler(function* () {
    const res = yield axios.get(`/incidents/severity_list`);
    let list = res.severity_list ? res.severity_list : [];
    list = Array.isArray(list) ? list : [];
    list = list.map((_) => {
      return { id: _, label: _ };
    });
    yield put(incidentActions.setSeverityTypes(list));
  }, "Failed to load Severity list");
}
function* fetchDateRangeEnums() {
  yield requestHandler(function* () {
    // const res = yield axios.get(`/incidents/date_range_list`);
    let list = [
      { id: "last_7_days", label: "Last 7 Days" },
      { id: "last_week", label: "Last Week (Sun-Sat)" },
      { id: "last_30_days", label: "Last 30 Days" },
      { id: "last_month", label: "Previous Month" },
      { id: "last_90_days", label: "Last 90 Days" },
      { id: "previous_quarter", label: "Previous Quarter" },
      { id: "last_365_days", label: "Last 365 Days" },
      { id: "previous_year", label: "Previous Year (Apr-Mar)" },
      { id: "over_all", label: "Entire History" },
    ];
    yield put(incidentActions.setDateRangeEnums(list));
  }, "Failed to load Date Range list");
}
function* fetchStatusTypes() {
  yield requestHandler(function* () {
    const res = yield axios.get(`/incidents/status_list`);
    let list = res.status_list ? res.status_list : [];
    list = Array.isArray(list) ? list : [];
    list = list.map((_) => {
      return { id: _, label: _ };
    });
    yield put(incidentActions.setStatusTypes(list));
  }, "Failed to load Status list");
}
function* fetchPriorities() {
  yield requestHandler(function* () {
    const res = yield axios.get(`/tasks/priority_list`);
    let list = res.priority_list ? res.priority_list : [];
    list = Array.isArray(list) ? list : [];
    list = list.map((_) => {
      return { id: _, label: _ };
    });
    yield put(incidentActions.setProrities(list));
  }, "Failed to load Status list");
}
function* fetchIncidents({ payload }) {
  yield requestHandler(function* () {
    const pageNo = payload && payload.pageNo ? payload.pageNo : 1;
    const pageSize = payload && payload.pageSize ? payload.pageSize : 15;
    const search = payload && payload.search ? payload.search : {};
    const res = yield axios.post(
      `/incidents/incident_list_search?page=${pageNo}&per_page=${pageSize}`,
      search
    );
    let incidents = res.incident_list ? res.incident_list : res;
    incidents = Array.isArray(incidents) ? incidents : [];
    incidents.sort(dateSorter.bind(null, "created_at"));
    let finalIncidents = [];
    for (let i = 0; i < incidents.length; i++) {
      let incident = incidents[i];
      incident = yield getIncident(incident, false);
      finalIncidents.push(incident);
    }
    const totalCount = res.total_count || finalIncidents.length;
    yield put(incidentActions.setTotalCount(totalCount));
    yield put(incidentActions.setIncidents(finalIncidents));
  }, "Failed to load Incidents");
}
function* fetchDeptStats({ payload }) {
  yield requestHandler(function* () {
    const body = {
      date_range_type: payload.dateRangeType,
      start_date: payload.startDate,
      end_date: payload.endDate,
    };
    const { department_status } = yield axios.post(
      `/incidents/department_dashboard`,
      body
    );
    const statusTypes = yield select((state) => state.incident.statusTypes);
    const departments = yield select(getDepartments);
    let deptStats = [];
    if (Array.isArray(departments)) {
      for (let i = 0; i < departments.length; i++) {
        const dept = departments[i];
        const deptStatus = department_status && department_status[dept.id];
        let row = {
          ...dept,
          overall: 0,
          avg_aging: (deptStatus && deptStatus.avg_aging) || 0,
          max_aging: (deptStatus && deptStatus.max_aging) || 0,
        };
        statusTypes.forEach(({ id }) => {
          let attribute = String(id).toLowerCase();
          let count =
            (deptStatus &&
              department_status[dept.id] &&
              department_status[dept.id].statuses &&
              department_status[dept.id].statuses[attribute]) ||
            0;
          row[attribute] = count;
          row.overall += count;
        });
        deptStats.push(row);
      }
    }
    yield put(incidentActions.setStatistics({ department: deptStats }));
  }, "Failed to load Statistics");
}
function* fetchSeverityStats({ payload }) {
  yield requestHandler(function* () {
    const body = {
      date_range_type: payload.dateRangeType,
      start_date: payload.startDate,
      end_date: payload.endDate,
    };
    const { severity_status } = yield axios.post(
      `/incidents/severity_dashboard`,
      body
    );
    const severities = yield select((state) => state.incident.severityTypes);
    const statusTypes = yield select((state) => state.incident.statusTypes);
    let severityStats = [],
      deptStats = [];
    if (Array.isArray(severities)) {
      for (let i = 0; i < severities.length; i++) {
        const severity = severities[i];
        const saverityStatus = severity_status && severity_status[severity.id];
        let row = {
          ...severity,
          overall: 0,
          avg_aging: (saverityStatus && saverityStatus.avg_aging) || 0,
          max_aging: (saverityStatus && saverityStatus.max_aging) || 0,
        };
        statusTypes.forEach(({ id }) => {
          let attribute = String(id).toLowerCase();
          let count =
            (saverityStatus &&
              severity_status[severity.id].statuses &&
              severity_status[severity.id].statuses[attribute]) ||
            0;
          row[attribute] = count;
          row.overall += count;
        });
        severityStats.push(row);
      }
    }
    yield put(incidentActions.setStatistics({ severity: severityStats }));
  }, "Failed to load Statistics");
}
function* createIncident({ payload }) {
  // alert("calling dispatch");
  const { body, useAI, navigateToDetailsPage, onComplete } = payload;
  yield requestHandler(function* () {
    try {
      yield setAlertAndLoading(true);

      // Step 1: Create the incident
      let res = yield axios.post(
        "/incidents",
        body,
        axios.getFormDataContentType()
      );

      if (res && res.id) {
        // Step 2: Add a comment
        let comment = new FormData();
        let initialComment = `${
          res.attachments?.length > 0 ? "Initial" : "No"
        } evidences submitted at the time of Incident Creation`;
        comment.append("comment[comment]", initialComment);
        comment.append("incident_id", res.id);

        yield axios.post(
          "/incidents/comment",
          comment,
          axios.getFormDataContentType()
        );

        // Fetch the latest incidents
        yield put(incidentActions.fetchIncidents());

        // Step 3: Process AI details if useAI is true
        if (useAI) {
          // alert("calling response from AI");
          yield call(fetchIncidentDetailsFromAI, body); // Wait for AI details to complete
        } else {
          yield put(incidentActions.setAIIncidentDetails(null));
        }

        // Step 4: Notify frontend that backend tasks are complete
        if (onComplete) {
          // alert("calling oncomplete");
          onComplete(); // Hide the AIML popup here
        }

        // Step 5: Show success message (ensure it doesn't disrupt the popup flow)
        yield setAlertAndLoading(null, {
          message: "Incident Created Successfully.",
        });

        // Step 6: Navigate to details page
        if (res.guid) {
          const targetLink = `/I/${res.guid}`;
          navigateToDetailsPage(targetLink); // Navigate after everything else is done
        }
      }
    } catch (error) {
      console.error("Error during incident creation:", error);
    }
  }, "Failed to Create Incident");
}

const validateResponse = (res) => {
  // 1. Check if res.message can be parsed as JSON
  let parsedResponse;
  try {
    parsedResponse = JSON.parse(res.message);
  } catch (error) {
    return false;
  }
  // 2. Check if all required keys exist
  const requiredKeys = [
    "Impact",
    "Observation",
    "RootCauseAnalysis",
    "Evidence",
    "Learning",
  ];
  for (const key of requiredKeys) {
    if (!parsedResponse.hasOwnProperty(key)) {
      return false;
    }
  }
  // 3. Check if the values for each key are non-empty arrays
  for (const key of requiredKeys) {
    const value = parsedResponse[key];
    if (!Array.isArray(value) || value.length === 0) {
      return false;
    }
  }
  // If all checks pass, return the parsed response
  return parsedResponse;
};
// Helper function to transform the response
const transformResponse = (response) => {
  const transformed = {};
  for (const [key, values] of Object.entries(response)) {
    transformed[key] = values.map((value, index) => {
      // Trim the value and ensure it has only one full stop at the end
      let trimmedValue = value.trim();
      if (!trimmedValue.endsWith(".")) {
        trimmedValue += ".";
      }
      return `${index + 1}. ${trimmedValue}`;
    });
  }
  return transformed;
};

function* fetchIncidentDetailsFromAI(params) {
  let body, onComplete;
  // Check if the input is an object with body and onComplete- when it is called by handleUpdate- dispatch function
  if (params?.payload) {
    let payload = params.payload;
    body = payload.body;
    onComplete = payload.onComplete;
  } else {
    //params is just the body when it is called inside createIncident function
    body = params;
  }
  // for (let [key, value] of body.entries()) {
  //   console.log(`Key: ${key}, Value: ${value}`);
  // }
  let isValid = false;
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    let finalResponse = null;
    for (let i = 0; i < 5; i++) {
      try {
        let res = yield axios.post(
          "/incidents/fetch_openai_response",
          body,
          axios.getFormDataContentType()
        );
        const validatedResponse = validateResponse(res);
        if (validatedResponse) {
          isValid = true;
          finalResponse = transformResponse(validatedResponse); // Transform the response
          // console.log({ res: finalResponse });
          break; // Exit the loop once a valid response is received
        } else {
          console.log(`Attempt ${i + 1}: Invalid response format, retrying...`);
        }
      } catch (error) {
        console.error(`Attempt ${i + 1}: API request failed`, error);
      }
    }
    // case where all attempts failed
    if (!isValid) {
      console.error("Failed to get a valid response after 5 attempts.");
    } else {
      yield put(incidentActions.setAIIncidentDetails(finalResponse));
    }
  }, "Failed to Fetch AI Details");
  // Call the onComplete callback if provided
  if (onComplete) {
    onComplete();
  }
}
function* fetchIncident({ payload }) {
  yield requestHandler(function* () {
    let { incidentId } = payload;
    if (Number.isNaN(Number(incidentId))) {
      incidentId = yield axios.get(
        "/incidents/get_incident_id?guid=" + incidentId
      );
      incidentId = incidentId.id;
    }
    let incident = yield axios.get(
      "/incidents/show_incident?incident_id=" + incidentId
    );
    if (incident) {
      incident = yield getIncident(incident);
    } else {
      incident = "NOTFOUND";
    }
    yield put(incidentActions.setIncident({ incident, incidentId }));
  }, "Failed to load Incident");
}
function* updateIncident({ payload }) {
  yield requestHandler(function* () {
    yield setAlertAndLoading(true);
    // if status is changing to Invalid, then add comment
    const updatedOptions = payload.payload;
    const status = updatedOptions.get("incident[status]");
    const justification = updatedOptions.get("incident[status_justificaiton]");
    if (
      typeof status === "string" &&
      status.toLowerCase() === "invalid" &&
      justification
    ) {
      const incidentId = updatedOptions.get("incident_id");
      const comment = new FormData();
      comment.append(
        `comment[comment]`,
        `Marked as Invalid.<br/>Justification: ${justification}`
      );
      comment.append("incident_id", incidentId);
      yield axios.post(
        "/incidents/comment",
        comment,
        axios.getFormDataContentType()
      );
    }
    let incident = yield axios.put(
      "/incidents/update_incident",
      updatedOptions,
      axios.getFormDataContentType()
    );
    incident = yield getIncident(incident);
    if (!payload.noMessage) {
      yield setAlertAndLoading(null, {
        message: "Incident Record has been updated",
      });
    }
    yield put(
      incidentActions.setIncident({ incident, incidentId: incident.id })
    );
  }, "Failed to update Incident");
}
function* updateIncidentResponder({ payload }) {
  yield requestHandler(function* () {
    const { incidentId, responderId } = payload;
    const body = {
      responder: { ent_usr_id: responderId },
      incident_id: incidentId,
    };
    let incident = yield axios.put("/incidents/add_inc_responder", body);
    incident = yield getIncident(incident);
    yield put(incidentActions.setIncident({ incident, incidentId }));
    yield setAlertAndLoading(null, {
      message: "Incident Record has been updated",
    });
  }, "Failed to Add Responder to Incidents");
}
function* removeIncidentResponder({ payload }) {
  yield requestHandler(function* () {
    const { incidentId, responderId } = payload;
    const body = {
      responder: { ent_usr_id: responderId },
      incident_id: incidentId,
    };
    let incident = yield axios.put("/incidents/remove_inc_responder", body);
    incident = yield getIncident(incident);
    yield put(incidentActions.setIncident({ incident, incidentId }));
    yield setAlertAndLoading(null, {
      message: "Incident Record has been updated",
    });
  }, "Failed to remove Responder to Incidents");
}
function* addIncidentComment({ payload }) {
  yield requestHandler(function* () {
    let incident = yield axios.post(
      "/incidents/comment",
      payload,
      axios.getFormDataContentType()
    );
    incident = yield getIncident(incident);
    yield put(
      incidentActions.setIncident({ incident, incidentId: incident.id })
    );
    yield setAlertAndLoading(null, {
      message: "Incident Record has been updated",
    });
  }, "Failed to Add comment to Incident");
}
function* createIncidentTask({ payload }) {
  yield requestHandler(function* () {
    let incident = yield axios.post("/incidents/create_task", payload);
    incident = yield getIncident(incident);
    yield put(
      incidentActions.setIncident({ incident, incidentId: incident.id })
    );
    yield setAlertAndLoading(null, {
      message: "Incident Task Created successfully",
    });
  }, "Failed to create task to Incident");
}
function* downloadIncidentReport({ payload }) {
  yield requestHandler(function* () {
    const { incidentId, password, filename } = payload;
    try {
      const body = { incident_id: incidentId, pwd: password };
      const res = yield fetch(
        axios.getFullPath(`/incidents/export_inc_report`),
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: axios.getHeaders().headers,
        }
      )
        .then((response) => response.blob())
        .catch((e) => {
          console.log(e);
          return null;
        });
      if (!filename.includes(".pdf")) {
        filename += ".pdf";
      }
      if (res) {
        yield setAlertAndLoading(null, {
          message: "Incident Report downloaded successfully",
        });
        saveAs(res, filename);
      } else {
        throw "Error";
      }
    } catch (error) {
      yield setAlertAndLoading(null, {
        success: false,
        message: "Incident Report Download Failed",
      });
    }
  }, "Failed to download Incident report");
}
function* shareIncidentReport({ payload }) {
  yield requestHandler(function* () {
    const { incidentId, password, emailIds } = payload;
    const body = {
      incident_id: incidentId,
      pwd: password,
      email_ids: emailIds,
    };
    yield axios.post("/incidents/share_inc_report", body);
    yield setAlertAndLoading(null, {
      message: "Incident Report shared successfully",
    });
  }, "Failed to share Incident report");
}
function* exportSeverityStats({ payload }) {
  const { filename, dateRangeType } = payload;
  yield requestHandler(function* () {
    const body = { date_range_type: dateRangeType };
    const res = yield axios.postExport(
      `/incidents/severity_dashboard_details_to_png`,
      body
    );
    if (res && res.error) {
      yield setAlertAndLoading(null, {
        message: `Failed to export : ${filename}`,
        success: false,
      });
    } else {
      saveAs(res, filename);
    }
  }, `Failed to export : ${filename}`);
}
function* exportDeptStats({ payload }) {
  const { filename, dateRangeType } = payload;
  yield requestHandler(function* () {
    const body = { date_range_type: dateRangeType };
    const res = yield axios.postExport(
      `/incidents/export_department_details_to_png`,
      body
    );
    if (res && res.error) {
      yield setAlertAndLoading(null, {
        message: `Failed to export : ${filename}`,
        success: false,
      });
    } else {
      saveAs(res, filename);
    }
  }, `Failed to export : ${filename}`);
}

SagaRegistry.register(function* userSaga() {
  yield takeEvery("incident/fetchDateRangeEnums", fetchDateRangeEnums);
  yield takeEvery("incident/fetchSeverityTypes", fetchSeverityTypes);
  yield takeEvery("incident/fetchStatusTypes", fetchStatusTypes);
  yield takeEvery("incident/fetchIncidents", fetchIncidents);
  yield takeEvery("incident/fetchDeptStats", fetchDeptStats);
  yield takeEvery("incident/fetchSeverityStats", fetchSeverityStats);
  yield takeEvery("incident/fetchPriorities", fetchPriorities);
  yield takeEvery("incident/createIncident", createIncident);
  yield takeEvery(
    "incident/fetchIncidentDetailsFromAI",
    fetchIncidentDetailsFromAI
  );
  yield takeEvery("incident/fetchIncident", fetchIncident);
  yield takeEvery("incident/updateIncident", updateIncident);
  yield takeEvery("incident/updateIncidentResponder", updateIncidentResponder);
  yield takeEvery("incident/removeIncidentResponder", removeIncidentResponder);
  yield takeEvery("incident/addIncidentComment", addIncidentComment);
  yield takeEvery("incident/createIncidentTask", createIncidentTask);
  yield takeEvery("incident/downloadIncidentReport", downloadIncidentReport);
  yield takeEvery("incident/shareIncidentReport", shareIncidentReport);
  yield takeEvery("incident/exportSeverityStats", exportSeverityStats);
  yield takeEvery("incident/exportDeptStats", exportDeptStats);
});
